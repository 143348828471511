<template>
  <el-dialog title="新增公告" width="900px" custom-class="v-dialog v-dialog--lgpadding" :visible.sync="visible" top="2vh" @opened="initEdit" :close-on-click-modal="false">
    <el-form label-width="10em">
      <el-form-item label="标题">
        <el-input v-model="item.Head" placeholder="请输入标题" style="width:215px;"></el-input>
      </el-form-item>
      <el-form-item label="起始时间">
        <el-date-picker type="datetimerange" v-model="range" start-placeholde="开始时间" end-placeholde="结束时间"></el-date-picker>
        <span class="i-link iml-1" @click="set7">未来7天</span>
        <span class="i-link"  @click="set14">未来14天</span>
        <span class="i-link"  @click="set30">未来30天</span>
      </el-form-item>
      <el-form-item label="限制个人用户">
        <el-checkbox v-model="item.OnlyCompany" label="是"></el-checkbox>
        <span class="iml-4">层级:<el-input v-model="item.ShowLevel" class="iml-0" :disabled="!item.OnlyCompany" placeholder="" style="width:60px;" maxlength="3"></el-input></span>
      </el-form-item>
      <el-form-item label="内容">
        <!-- <el-input
          type="textarea"
          :autosize="{ minRows: 15, maxRows: 20}"
          placeholder="请输入内容"
          v-model="item.Body">
        </el-input> -->
        <div ref="edit"></div>
        <el-button type="text" size="medium" @click="view">预览</el-button>
      </el-form-item>
    </el-form>
    <dialog-notify append-to-body ref="dialogNotify"></dialog-notify>
    <div slot="footer">
      <el-button size="medium" class="border" plain @click="visible=false">取消</el-button>
      <el-button type="primary" class="border" plain size="medium" @click="save">保存</el-button>
    </div>
  </el-dialog>
</template>

<script>
import miment from 'miment'
import {mapGetters} from 'vuex'
import DialogNotify from './dialog-notify'
import E from 'wangeditor'
import * as API from '@/api/notify'
export default {
  components: {
    DialogNotify
  },
  data() {
    return {
      id: null,
      visible: false,
      item: {
          NID: 0,
          Head: '通知',
          Body: '',
          BeginTime: null,
          EndTime: null,
          OnlyCompany: false,
          ShowLevel: 3,
          OnlyShowOnceTime: false,
          UpdateTime: null,
          IsDeleted: false,
          CreateTime: miment(),
          CreateUserId: 0
      },
      range: [miment(), miment().add(7, 'DD')]
    }
  },
  computed: {
    ...mapGetters([
      'loginInfo'
    ])
  },
  mounted() {
  },
  methods: {
    open(id) {
      this.id = id

      if (!id) {
        this.item = Object.assign(this.item, {
          NID: 0,
          Head: '通知',
          Body: '',
          BeginTime: null,
          EndTime: null,
          OnlyCompany: false,
          ShowLevel: 3,
          OnlyShowOnceTime: false,
          UpdateTime: null,
          IsDeleted: false,
          CreateTime: miment(),
          CreateUserId: 0
        })
        this.range = [miment(), miment().add(7, 'DD')]
      }

      this.visible = true
      this.initEdit()
    },
    initEdit() {
      if (!this.editor) {
        setTimeout(() => {
          this.editor = new E(this.$refs.edit)
          this.editor.create()
        }, 25)
      }
    },
    save() {
      this.item.BeginTime = this.range[0]
      this.item.EndTime = this.range[1]
      this.item.Body = this.editor.txt.html()
      API.AddNotifyInfo(this.item).then(ret => {
        if (ret.data.Message) {
          this.$message({
            message: ret.data.Message,
            type: 'error'
          })
          return
        }
        this.visible = false
        this.$emit('refresh')
      })
    },
    view() {
      this.item.Body = this.editor.txt.html()
      this.$refs.dialogNotify.view(this.item)
    },
    set7() {
      this.range = [miment(), miment().add(7, 'DD')]
    },
    set14() {
      this.range = [miment(), miment().add(14, 'DD')]
    },
    set30() {
      this.range = [miment(), miment().add(30, 'DD')]
    }
  }
}
</script>

<style lang="scss" scoped>

</style>

